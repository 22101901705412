@use "../0-settings/colors";
@use "../0-settings/core";

.c-site-header {
  background: colors.$color-white;
}
  .c-site-header__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

    .c-site-header__logo {
      padding: core.$baseline*0.5 core.$baseline;
      white-space: nowrap;
    }

    .c-site-header__nav {
      flex-grow: 2;

      a {
         line-height: 2*core.$baseline;
      }

      a,
      a:visited {
        display: block;
        font-family: 'Roboto Black';
        font-size: 2rem;
        transform: rotate(-5deg);
        color: colors.$color-black;
      }

      a:hover {
        color: colors.$color-blue;
        border-bottom-width: .1em;
      }
    }
