@use "../0-settings/colors";
@use "../1-tools/mixins";

kbd {
  border-radius: 4px;
	color: colors.$color-green-dark;
	@include mixins.code;
  box-shadow: 1px 1px 0 2px gainsboro;
	margin-left: .25ch;
	margin-right: .5ch;
}
