.o-links-list {
  display:flex;
  flex-wrap:wrap;
  list-style-type:none;
  padding: 0;

  // Overrides default margin.
  li + li {
    margin: 0
  }

  li {
    padding: .5em;
  }
  justify-content:space-around
}

.o-links-list--evenly {
  justify-content: space-evenly;
}

.o-links-list--align-start {
  align-content: flex-start;
}

.o-links-list--column {
  flex-direction: column;
  li {
    padding: 0.25em 0;
  }
}
