@use "../0-settings/colors";
@use "../0-settings/core";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: colors.$heading-color;
	margin-bottom: core.$baseline;
}

h1 {
  font-family: core.$black-font-stack;
}

h1 {
  font-size: core.$h1;
  line-height: core.$h1;
  margin-top: calc((#{core.$baseline} - #{core.$h1}) + #{core.$baseline});
}

h2 {
  font-size: core.$h2;
  line-height: core.$h2;
  margin-top: calc((#{core.$baseline} - #{core.$h2}) + #{core.$baseline});

  // Fixes underline (border-bottom) striking through letter descenders
  & a,
  & a:visited {
    line-height: calc(#{core.$h2} + .3em);
  }
}

h3 {
  font-size: core.$h3;
  line-height: core.$h3;
  margin-top: calc((#{core.$baseline} - #{core.$h3}) + #{core.$baseline});
}

h4 {
  font-size: core.$h4;
  line-height: core.$h4;
  margin-top: calc((#{core.$baseline} - #{core.$h4}) + #{core.$baseline});
}

h5 {
  font-size: core.$h5;
  line-height: core.$h5;
  margin-top: calc((#{core.$baseline} - #{core.$h5}) + #{core.$baseline});
}

h6 {
  font-size: core.$h6;
  line-height: core.$h6;
  margin-top: calc((#{core.$baseline} - #{core.$h6}) + #{core.$baseline});
}
