$color-white: #fefefd;
$color-black: #010101;
$color-blue: #2f70b4;
$color-blue-dark: #2A4ACB;
$color-yellow: #ffbf47;
$color-yellow-bright: #fd0;
$color-green: #018d85;
$color-green-dark: #055e5c;
$color-red: #9D3342;
$color-gray: #565656;
$color-gray-light: #dddddd;
$color-gray-lighter: #eeeeee;
$color-gray-dark: #454545;

$heading-color: $color-black;
$link-color: $color-blue-dark;
$link-underline-color: $color-green;
$focus-color: $color-yellow-bright;
