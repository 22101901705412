@use "sass:math";

@use "../0-settings/colors";
@use "../0-settings/core";

.c-entry {
	// margin-bottom: 2*$baseline;
	h2:first-child {
		margin-bottom: math.div(core.$baseline, 6);
	}
	p {
		margin-top: 0;
		color: colors.$color-gray-dark;
	}
	&:not(:last-of-type) {
		border-bottom: solid colors.$color-gray .03em;
	}
}

	.c-entry__link {
		border-bottom-width: 0;

		&:hover,
		&:focus {
			border-bottom-width: .08em;
		}
	}
