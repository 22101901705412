@use "sass:math";

@use "../0-settings/colors";
@use "../0-settings/core";

.c-form__field {
  margin-bottom: core.$baseline;

  legend {
    margin-bottom: math.div(core.$baseline, 6);
    font-family: core.$black-font-stack;
    font-weight: normal;
  }
}

  .c-form__label-text {
    display: block;
    margin-bottom: .25rem;
    font-family: core.$black-font-stack;
    font-weight: normal;
  }

  .c-form__hint {
    display: block;
    margin-bottom: math.div(core.$baseline, 3);
    color: colors.$color-gray;
    font-weight: normal;
  }

  .c-form__field-error {
    display: flex;
    align-items: center;
    margin-bottom: math.div(core.$baseline, 6);
    color: colors.$color-red;
    font-family: core.$black-font-stack;
    font-weight: normal;
  }

  .c-form__field-radio label {
    line-height: 1.25;
    background-color: colors.$color-gray-light;
    margin-bottom: math.div(core.$baseline, 3);
    display: block;
    padding: .5em;
    border: 2px solid colors.$color-gray-light;
  }

  .c-form__field-radio input {
    margin-right: core.$baseline*0.5;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
  }

  .c-form__field-radio label:hover {
    border-color: colors.$color-gray;
  }
