@use "../0-settings/colors";
@use "../1-tools/mixins";

code {
	@include mixins.code;
}

pre > code {
	display: block;
	padding: 1rem;
	overflow-x: scroll;
	background-color: colors.$color-black;
	color: colors.$color-white;
	white-space: pre;
}
