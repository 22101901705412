@use "../0-settings/colors";
@use "../0-settings/core";
@use "../1-tools/mixins";

.c-post-header {
	text-align: center;
	margin-bottom: 3rem;

	& > * {
		margin-bottom: .8rem;
	}
}

.c-post-date {
	display: block;
	color: colors.$color-black;
	font-size: 1rem;
	margin: .5em 0;
}

.c-post-date--dimmed {
	margin-top: 0;
	color: colors.$color-gray;
}

.c-post-footer {
    display: block;
    margin-top: 3rem;
    padding: core.$spacing-medium;
    border-radius: .3em;
	background-color: colors.$color-green;

	a,
	a:visited {
		font-size: core.$font-size-text-medium;
		color: colors.$color-white;
		border-bottom: .1em solid colors.$color-white;
	}

	a:focus {
		@include mixins.focus;
		color: colors.$color-black;
	}
}
