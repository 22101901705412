/* ==========================================================================
   #CORE
   ========================================================================== */

@use "sass:math";

$page-font-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$regular-font-stack: 'Roboto Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$black-font-stack: 'Roboto Black', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-min: 16px;
$base-max: 20px;
$css-lock-min: 767px;
$css-lock-max: 1200px;
$baseline: 1.5rem;
$ms: 1.125; // Modular scale (http://type-scale.com/, http://www.modularscale.com/)

// Font sizes
$font-size-text-xxx-large: $ms * $ms * $ms * $ms * $ms * 1rem;
$font-size-text-xx-large: $ms * $ms * $ms * $ms * 1rem;
$font-size-text-x-large: $ms * $ms * $ms * 1rem;
$font-size-text-large: $ms * $ms * 1rem;
$font-size-text-medium: $ms * 1rem;
$font-size-text-small: 1rem;
$font-size-text-x-small: math.div(1rem, $ms);

// Heading sizes
$h1: $font-size-text-xxx-large;
$h2: $font-size-text-xx-large;
$h3: $font-size-text-x-large;
$h4: $font-size-text-large;
$h5: $font-size-text-medium;
$h6: $font-size-text-small;

// Spacings sizes
$spacing-xx-large: 4em;
$spacing-x-large: 3em;
$spacing-large: 1.5em;
$spacing-medium: 1em;
$spacing-small: .75em;
$spacing-x-small: .5em;
$spacing-xx-small: .25em;
