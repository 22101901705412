@use "../0-settings/core";
@use "../1-tools/mixins";

li + li {
  // Use a kinda vertical rhythm trick so that it looks decent.
  margin-top: calc((1rem - 1em) + 1rem);
  margin-bottom: 1rem;
}

ul,
ol,
dl {
  line-height: core.$baseline;

  @include mixins.strip-leading-trailing-margins;
}

