@use "../0-settings/colors";
@use "../1-tools/mixins";

[type="date"],
[type="email"],
[type="tel"],
[type="text"],
[type="search"],
textarea {
  width: 100%;
  padding: .25em;
  -webkit-appearance: none;
  border: 2px solid colors.$color-green;
}

select {
	width: 100%;
}

[type=text],
[type=tel],
[type=email],
[type=date],
[type=radio],
[type=checkbox],
[type=file],
[type=search],
textarea,
select {
  &:focus {
    outline: 0.125rem solid colors.$color-yellow;
  }
}

[type=submit],
button {
	&:focus {
		@include mixins.focus;
		border-bottom-style: solid;
		border-bottom-width: .15em;
		outline: none;
	}
}

fieldset {
  border: none;
  padding: 0;
}
