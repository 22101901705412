.c-logo {
  &:not(:focus) {
	border-width: 0;
  }

  svg {
    width: 8em;
    height: 8em;
    border-radius: 50%;
    transform: rotate(-5deg);
    box-shadow: 3px 5px 5px lightgray;
  }
}

