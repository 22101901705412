@use "../0-settings/colors";
@use "../1-tools/mixins";

a,
a:visited {
	outline: 0;
	font-weight: bold;
	color: colors.$link-color;
	text-decoration: none;
	border-bottom: .1em solid colors.$link-underline-color;
}

a:hover {
	border-bottom-width: .15em;
}

a:focus {
	@include mixins.focus;
}

