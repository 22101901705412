@use "../0-settings/colors";
@use "../0-settings/core";
@use "../1-tools/mixins";

.c-footer {
	margin: 0;
	background-color: colors.$color-black;

	a,
	a:visited {
		color: colors.$color-white;
		border-bottom-color: colors.$color-white;
	}

	a:focus {
		@include mixins.focus;
	}

}

.c-footer__nav {
	padding-top: 0;
}

.c-footer__copyright {
	padding: core.$baseline*0.5;
	font-size: 16px;
	color: colors.$color-white;
	text-align: center;
}
