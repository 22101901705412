@use "sass:math";

@use "../0-settings/core";
@use "../1-tools/mixins";

header {
  margin-bottom: core.$baseline*math.div(2, 3);
}

main {
  margin-bottom: core.$baseline*3;
  // Pushes the footer down for tiny content pages.
  min-height: 60vh;
}

header,
main,
footer,
nav {
  padding: core.$baseline core.$baseline 0 core.$baseline;
  @include mixins.strip-leading-trailing-margins;
}

article > header {
  padding: 0;
}
