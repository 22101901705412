@use "../0-settings/colors";
@use "../0-settings/core";
@use "../1-tools/mixins";

/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  font-family: core.$page-font-stack;
  color: colors.$color-black;
  height: 100%;

  @include mixins.fluid-type(font-size, core.$css-lock-min, core.$css-lock-max, core.$base-min, core.$base-max);
}
