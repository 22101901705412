@use "../0-settings/colors";
@use "functions";

//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	@each $property in $properties {
		#{$property}: $min-value;
	}

	@media (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(#{$min-value} + #{functions.strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{functions.strip-unit($max-vw - $min-vw)});
		}
	}

	@media (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}

@mixin strip-leading-trailing-margins() {
	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

@mixin code() {
	border-radius: .2em;
	padding: .1em .2em;
	background-color: colors.$color-gray-lighter;
	color: colors.$color-gray-dark;
	white-space: pre-wrap;
}

@mixin focus() {
	color: colors.$color-black;
	background-color: colors.$focus-color;
	border-bottom-color: colors.$color-black;
}
